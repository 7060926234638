<template>
  <v-container fluid>
  <span>POS</span>
    <v-data-table 
      dense
      :headers="headers"
      :items="items"
      item-key="id" 
      display-text="Name"
      class="elevation-1 mt-4"
      @click:row="onClickRow($event)"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
    <template slot="no-data">No records found.</template>
    </v-data-table>
    <v-overlay opacity="0" v-if="loading">
      <v-progress-circular
        indeterminate
        color="red"
        size="64"
      ></v-progress-circular>
    </v-overlay>

  </v-container>
</template>
<style lang="scss">
</style>
<script>
import api from '@/api.js';

export default {
  name: "countries-view",

  props: {
  },

  data: () => ({
    loading: false,
    itemsPerPage: -1,
    items: [],
    headers: [],
    search: null
  }),

  async created() {
    const me = this;
    me.headers = me.createHeaders();
    me.refresh();
  },

  updated() {
    document.title = "Jura Orga App | POS";
  },

  methods: {
    async refresh() {
       const me = this;
       me.loading = true;
       const res = await api.get(`pos`);
       me.items = res.data;
       me.loading = false;
    },

    addNew() {
      this.$router.push(`pos/-1`);
    },

    onClickRow(e) {
      this.$router.push(`pos/${e.id}`);
    },

    createHeaders() {
      //const me = this;
      const headers = [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "Name",
        },
      ];
      return headers;
    },
  },
};
</script>