<template>
  <v-container fluid>
    <span>POS Details</span>
    <v-form
      class="mt-4"
      ref="form"
      v-model="valid"
      v-if="loaded"
    >
      <v-row>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field
              ref="txtName"
              dense
              :rules="requiredRules"
              required
              @focus="$event.target.select()"
              label="POS Name"
              v-model="record.Name"
              @input="onInput($event)"
              @keydown="onKeydown($event)"
            >
            </v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field
              dense
              type="number"
              label="Latitude"
              v-model="record.Latitude"
            >
            </v-text-field>
          </v-card>
        </v-col>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field
              dense
              type="number"
              label="Longitude"
              v-model="record.Longitude"
            >
            </v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field
              dense
              type="date"
              :rules="requiredRules"
              required
              label="Active from"
              v-model="aktivVonDate"
            >
            </v-text-field>
          </v-card>
        </v-col>
        <v-col cols="auto" style="min-width:350px;max-width:500px">
          <v-card elevation="2" class="pa-8">
            <v-text-field
              dense
              type="date"
              label="Active till"
              v-model="aktivBisDate"
            >
            </v-text-field>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<style lang="scss">
  h1 {
    font-size: 18px;
    color:#8f98a2;
    margin-bottom: 12px;
    margin-left: 4px;
  }

  .required label::after {
      content: "*";
  }  
</style>

<script>
import detailViewData from '@/common/detailViewData.js';
import detailViewMethods from '@/common/detailViewMethods.js';
import api from '@/api';

export default {
  name: "pos-details-view",

  props: {},

  data: () => ({
    ...detailViewData,
  }),

  computed: {
    aktivVonDate: {
    get() {
      return this.record.AktivVon
        ? this.record.AktivVon.substring(0, 10) 
        : null;
    },
    set(value) {
      if (value) {
        const time = "T00:00:00Z";
        this.record.AktivVon = `${value}${time}`;
      } else {
        this.record.AktivVon = null;
      }
    },
  },
  aktivBisDate: {
    get() {
      return this.record.AktivBis
        ? this.record.AktivBis.substring(0, 10)
        : null;
    },
    set(value) {
      if (value) {
        const time = "T00:00:00Z";
        this.record.AktivBis = `${value}${time}`;
      } else {
        this.record.AktivBis = null;
      }
    },
  },
  },

  created() {
    this.init();
  },

  mounted() {
    setTimeout(() => {
      this.$refs.txtAreaName.focus();
    }, 300);
  },

  updated() {
    document.title = "Jura Orga App | POS Details";
  },

  beforeRouteLeave(to, from, next) {
    this.confirmSaveChangesBeforeLeave(next);
  },

  methods: {
    ...detailViewMethods,

    async init() {
      this.url = "PosDetail";
      await this.initialize();
    },

    createNewRecord() {
      const rec = {
        Name: "",
      };
      return rec;
    },

    async onBeforeSave() {},

    onValidationErrors() {
      //console.log("onValidationErrors called in AreaDetailsView");
    },

    async duplicate() {
      if (!this.record) {
          console.error("No POS entity to duplicate.");
          return;
      }

      if (!this.record.AktivBis) {
          console.error("Cannot duplicate POS because AktivBis is not set.");
          alert("Duplication is only allowed if the AktivBis date is set."); // Optional user feedback
          return;
      }

      const currentDate = this.record.AktivBis.split('T')[0]; // Extract YYYY-MM-DD
      const [year, month, day] = currentDate.split('-').map(Number);

      const newDate = new Date(Date.UTC(year, month - 1, day + 1)); // Add 1 day in UTC
      const formattedDate = `${newDate.getUTCFullYear()}-${String(newDate.getUTCMonth() + 1).padStart(2, '0')}-${String(newDate.getUTCDate()).padStart(2, '0')}`;


      const newPos = {
          ...this.record,
          id: crypto.randomUUID(),
          Name: `${this.record.Name} (Copy)`,
          AktivVon: formattedDate,
          AktivBis: null // Reset AktivBis
      };

      try {
          const response = await api.createPOS(newPos);
          console.log("Duplicated POS successfully:", response);
          this.$router.push({ name: 'PosDetailsView', params: { id: response.id } }); // Navigate to the new record
      } catch (error) {
          console.error("Error duplicating POS:", error);
      }
  }
  },
};
</script>
