import Vue from "vue";
import { LogLevel } from "@azure/msal-browser";
import msalPlugin from "./plugins/msalPlugin";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VuetifyConfirm from "vuetify-confirm";
import DialogPromise from "vuetify-dialog-promise";
import "../styles.css";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENTID,
    authority: process.env.VUE_APP_AUTHORITY,
    redirectUri: process.env.VUE_APP_CLIENTURL,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: process.env.VUE_APP_CLIENTURL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  mode: "redirect",
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.error(message);
        }
      },
    },
  },
};

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  // TODO: Perform any custom logic or log to server
  console.log("ERROR!!!");
  console.log(err);
  console.log(vm);
  console.log(info);
};

Vue.use(DialogPromise, {
  locale: "en",
  snackbarX: "center",
  snackbarY: "bottom",
  theme: { dark: false },
});
Vue.use(VuetifyConfirm, {
  vuetify,
  // color: 'warning',
  buttonTrueText: "Ja",
  buttonFalseText: "Nein",
  icon: "mdi-alert",
});

Vue.use(msalPlugin, msalConfig);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  created() {
    console.log("main.js created");

    window.EventBus.$on("signedIn", (res) => {
      console.log(`signedIn: ${res.data.username}`);
    });

    if (!this.$msal.isAuthenticated()) {
      console.log("main.js ! msal.isAuthenticated");
      this.$msal.signIn();
    }
    this.$mount("#app");
  },
});
