<template>
  <v-list
          nav dense class="px-5"
        >
      <v-list-item-group>
        <v-subheader>Assignments</v-subheader>
        <v-list-item link to="/orgStructure">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-file-tree</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Organisation Structure</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group>
        <v-subheader>Master data</v-subheader>      
        <v-list-item link to="/employees">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Manage Employees</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/salesGroups">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Manage Sales Groups</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/countries">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Manage Countries</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/areas">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Manage Areas</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/pos">
          <v-list-item-icon class="me-3">
            <v-icon>mdi-router</v-icon>
          </v-list-item-icon>
          <v-list-item-title>POS</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>
<style scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>

<script>
export default {
  name: 'sideMenu',
  created() {
  },
  mounted() {
  },
  emits: {
  },
  computed: {
  },
  methods: {
  },
  data: () => ({
  }),
};
</script>
